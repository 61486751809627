import React from 'react';
import { FileDown, HelpCircle, List, Image, MousePointer2 } from 'lucide-react';
import { downloadSampleCsv } from '../utils/sampleData';

export function HelpSection() {
  return (
    <div id="help-section" className="bg-white rounded-lg shadow-sm p-6 mb-8 mt-4">
      <div className="flex items-center gap-2 mb-6">
        <HelpCircle className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold text-gray-900">How it works</h2>
      </div>

      <div className="space-y-6">
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0">
            <List className="w-6 h-6 text-gray-400" />
          </div>
          <div>
            <h3 className="font-medium text-gray-900 mb-1">1. Prepare your data</h3>
            <p className="text-gray-600 mb-2">
              Download our CSV template and fill it with your student information
            </p>
            <button
              onClick={downloadSampleCsv}
              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              <FileDown className="w-4 h-4 mr-1" />
              Download CSV template
            </button>
          </div>
        </div>

        <div className="flex items-start gap-4">
          <div className="flex-shrink-0">
            <Image className="w-6 h-6 text-gray-400" />
          </div>
          <div>
            <h3 className="font-medium text-gray-900 mb-1">2. Design your diploma</h3>
            <p className="text-gray-600">
              Upload a background image and customize the layout of your diploma
            </p>
          </div>
        </div>

        <div className="flex items-start gap-4">
          <div className="flex-shrink-0">
            <MousePointer2 className="w-6 h-6 text-gray-400" />
          </div>
          <div>
            <h3 className="font-medium text-gray-900 mb-1">3. Generate diplomas</h3>
            <p className="text-gray-600">
              Preview and generate individual diplomas for each student
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
