export const sampleCsvContent = `First Name,Last Name,Course Name,Graduation Date,Final Grade,Student ID
John,Doe,Web Development,2024-03-15,A,STU001
Jane,Smith,Data Science,2024-03-15,A+,STU002
Michael,Johnson,UI/UX Design,2024-03-15,A-,STU003`;

export const downloadSampleCsv = () => {
  const blob = new Blob([sampleCsvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sample-students.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
