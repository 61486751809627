import React from 'react';
import { useDiplomaStore } from '../store/diplomaStore';
import { FONT_FAMILIES, FONT_SIZES } from '../config/constants';

export function FontControls() {
  const { elements, selectedElement, updateElementFont, setSelectedElement } = useDiplomaStore();

  if (!selectedElement) {
    return (
      <div className="bg-gray-50 rounded-lg p-4">
        <p className="text-sm text-gray-500">Select an element to customize its font</p>
      </div>
    );
  }

  const element = elements.find(el => el.id === selectedElement);
  if (!element) return null;

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <h3 className="font-medium text-gray-900 mb-3">Font Settings</h3>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Font Family
          </label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={element.font.family}
            onChange={(e) => updateElementFont(element.id, { family: e.target.value })}
          >
            {FONT_FAMILIES.map((font) => (
              <option key={font.value} value={font.value} style={{ fontFamily: font.value }}>
                {font.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Font Size
          </label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={element.font.size}
            onChange={(e) => updateElementFont(element.id, { size: Number(e.target.value) })}
          >
            {FONT_SIZES.map((size) => (
              <option key={size} value={size}>
                {size}px
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
