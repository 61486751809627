import React, { useState, useEffect } from 'react';
import { useCreditsStore } from '../store/creditsStore';
import { useAuthStore } from '../store/authStore';
import { CheckCircle, Loader2, X, LogIn, Sparkles, Clock, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { DbCreditPackage } from '../types/credits';

export function CreditsModal({ isOpen, onClose, requiredCredits }: {
  isOpen: boolean;
  onClose: () => void;
  requiredCredits: number;
}) {
  const { credits, addCredits } = useCreditsStore();
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const [processingPackageId, setProcessingPackageId] = useState<string | null>(null);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [packages, setPackages] = useState<DbCreditPackage[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      fetchPackages();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setPurchaseComplete(false);
      setProcessingPackageId(null);
    }
  }, [isOpen]);

  const fetchPackages = async () => {
    try {
      const { data, error } = await supabase
        .from('credit_packages')
        .select('*')
        .eq('active', true)
        .order('price', { ascending: true });

      if (error) throw error;
      setPackages(data || []);
    } catch (err) {
      console.error('Error fetching packages:', err);
      setError('Failed to load pricing packages');
    }
  };

  const handleGetCredits = async (packageId: string, amount: number) => {
    if (!user) {
      navigate(`/auth/signup?redirect=/pricing&package=${packageId}`);
      return;
    }

    setProcessingPackageId(packageId);
    try {
      const success = await addCredits(amount, packageId);
      if (success) {
        setPurchaseComplete(true);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } finally {
      setProcessingPackageId(null);
    }
  };

  if (error) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950/50">
        <div className="bg-white rounded-xl shadow-xl p-6 w-[32rem] max-w-full">
          <p className="text-red-600 text-center">{error}</p>
        </div>
      </div>
    );
  }

  if (purchaseComplete) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950/50">
        <div className="bg-white rounded-xl shadow-xl p-8 w-[32rem] max-w-full text-center">
          <Sparkles className="w-16 h-16 text-green-500 mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-gray-900 mb-2">
            Credits Added Successfully!
          </h3>
          <p className="text-gray-600 mb-2">
            Your credits have been added to your account.
          </p>
          <p className="text-sm text-gray-500">
            Redirecting you back to continue...
          </p>
        </div>
      </div>
    );
  }

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950/50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="bg-white rounded-xl shadow-xl p-6 w-[40rem] max-w-full"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mb-6 flex justify-between items-center">
          <div>
            <div className="flex items-center gap-2 mb-1">
              <h2 className="text-2xl font-semibold text-gray-900">
                Limited Time Offer
              </h2>
              <span className="bg-amber-100 text-amber-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                Special Launch Pricing
              </span>
            </div>
            <p className="text-gray-600 text-sm">
              Get premium credits at no cost during our platform launch
            </p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close modal"
          >
            <X className="w-5 h-5 text-gray-400 hover:text-gray-600" />
          </button>
        </div>

        <div className="mb-6 p-4 bg-blue-50 rounded-lg">
          <div className="flex items-center gap-3 mb-3">
            <Clock className="w-5 h-5 text-blue-600" />
            <h3 className="font-medium text-blue-900">
              Limited Time Launch Offer
            </h3>
          </div>
          <p className="text-blue-800 text-sm mb-3">
            For a limited time, we're offering our premium credit packages completely FREE to early adopters.
          </p>
          <div className="flex items-center gap-2 text-sm text-blue-700">
            <Shield className="w-4 h-4" />
            <span>No credit card required • Instant access • Premium features included</span>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex items-center gap-2 text-gray-900">
            <span className="font-medium">Credits needed:</span>
            <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-md text-sm">
              {requiredCredits} credits
            </span>
            {user && (
              <span className="text-sm text-gray-500 ml-2">
                (Current balance: {credits} credits)
              </span>
            )}
          </div>
        </div>

        <div className="grid gap-4">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="border rounded-lg p-4 hover:border-blue-500 transition-colors"
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-medium text-gray-900">{pkg.name}</h3>
                <div className="text-right">
                  <span className="text-sm text-gray-500 line-through">
                    ${pkg.price}
                  </span>
                  <span className="text-2xl font-semibold text-green-600 ml-2">
                    FREE
                  </span>
                </div>
              </div>
              <p className="text-gray-600 mb-4">{pkg.description}</p>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-500">
                    {pkg.credits} credits
                  </span>
                  <span className="bg-green-100 text-green-700 text-xs px-2 py-0.5 rounded-full">
                    Save ${pkg.price}
                  </span>
                </div>
                <button
                  onClick={() => handleGetCredits(pkg.id, pkg.credits)}
                  disabled={!!processingPackageId}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                >
                  {processingPackageId === pkg.id ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    <>
                      {!user && <LogIn className="w-4 h-4" />}
                      {user ? 'Get Free Credits' : 'Sign Up & Get Free Credits'}
                    </>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 pt-6 border-t border-gray-200">
          <div className="flex items-center justify-center gap-6 text-sm text-gray-500">
            <div className="flex items-center gap-2">
              <Shield className="w-4 h-4" />
              <span>Secure Processing</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              <span>Instant Activation</span>
            </div>
            <div className="flex items-center gap-2">
              <CheckCircle className="w-4 h-4" />
              <span>No Expiration</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditsModal;
