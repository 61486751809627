export const FONT_FAMILIES = [
  { name: 'Playfair Display', value: 'Playfair Display' },
  { name: 'Montserrat', value: 'Montserrat' },
  { name: 'Cormorant Garamond', value: 'Cormorant Garamond' },
  { name: 'Old Standard TT', value: 'Old Standard TT' },
  { name: 'Libre Baskerville', value: 'Libre Baskerville' }
];

export const FONT_SIZES = [12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48];

export const DIPLOMA_DIMENSIONS = {
  width: 2069,
  height: 1382,
  ratio: 1.414
};

export const PREVIEW_DIMENSIONS = {
  width: 800,
  height: 600
};

export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
