import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { PlusCircle } from 'lucide-react';
import { useDiplomaStore } from '../store/diplomaStore';

export function ImageElementControl() {
  const { addImageElement } = useDiplomaStore();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        // Calculate scaled dimensions while maintaining aspect ratio
        const maxSize = 200;
        const scale = Math.min(maxSize / img.width, maxSize / img.height);
        const width = img.width * scale;
        const height = img.height * scale;

        addImageElement({
          type: 'image',
          src: reader.result as string,
          position: { x: 400, y: 300 }, // Center of diploma
          width,
          height,
        });
      };
      img.src = reader.result as string;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }, [addImageElement]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
  });

  return (
    <div className="border-t border-gray-200 mt-4 pt-4">
      <div {...getRootProps()} className="flex items-center gap-2 text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
        <PlusCircle className="w-4 h-4" />
        <span>Add Image Element</span>
        <input {...getInputProps()} />
      </div>
    </div>
  );
}
