import React, { useState } from 'react';
import { PlusCircle, Type, X } from 'lucide-react';
import { useDiplomaStore } from '../store/diplomaStore';

export function CustomTextControl() {
  const [isAdding, setIsAdding] = useState(false);
  const [newText, setNewText] = useState('');
  const { addCustomElement } = useDiplomaStore();

  const handleAddText = () => {
    if (newText.trim()) {
      addCustomElement({
        type: 'custom',
        text: newText,
        position: { x: 400, y: 300 }, // Center of diploma
        font: {
          family: 'Montserrat',
          size: 24,
          color: '#000000'
        }
      });
      setNewText('');
      setIsAdding(false);
    }
  };

  return (
    <div className="border-t border-gray-200 mt-4 pt-4">
      {!isAdding ? (
        <button
          onClick={() => setIsAdding(true)}
          className="flex items-center gap-2 text-sm text-blue-600 hover:text-blue-800"
        >
          <PlusCircle className="w-4 h-4" />
          Add Custom Text
        </button>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <Type className="w-4 h-4 text-gray-400" />
            <input
              type="text"
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              placeholder="Enter custom text"
              className="flex-1 text-sm border border-gray-300 rounded-md px-3 py-1.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={() => setIsAdding(false)}
              className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
            >
              <X className="w-4 h-4" />
            </button>
            <button
              onClick={handleAddText}
              className="px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
              disabled={!newText.trim()}
            >
              Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomTextControl;
