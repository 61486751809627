import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ScrollText, Loader2 } from 'lucide-react';

// Custom components for ReactMarkdown
const MarkdownComponents = {
  h1: ({ children }) => (
    <h1 className="text-3xl font-bold text-gray-900 mt-8 mb-6">{children}</h1>
  ),
  h2: ({ children }) => (
    <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">{children}</h2>
  ),
  h3: ({ children }) => (
    <h3 className="text-xl font-medium text-gray-800 mt-6 mb-3">{children}</h3>
  ),
  p: ({ children }) => (
    <p className="text-gray-600 leading-relaxed mb-4">{children}</p>
  ),
  ul: ({ children }) => (
    <ul className="list-disc list-outside ml-6 mb-6 space-y-2 text-gray-600">{children}</ul>
  ),
  ol: ({ children }) => (
    <ol className="list-decimal list-outside ml-6 mb-6 space-y-2 text-gray-600">{children}</ol>
  ),
  li: ({ children }) => (
    <li className="leading-relaxed">{children}</li>
  ),
  strong: ({ children }) => (
    <strong className="font-semibold text-gray-900">{children}</strong>
  ),
  hr: () => (
    <hr className="my-8 border-t border-gray-200" />
  ),
  blockquote: ({ children }) => (
    <blockquote className="border-l-4 border-blue-500 pl-4 py-2 my-4 text-gray-600 italic">
      {children}
    </blockquote>
  ),
};

// Base component for legal pages
const LegalPage = ({ title, children, isLoading }) => {
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex items-center gap-3 mb-8 pb-6 border-b border-gray-200">
            <ScrollText className="w-8 h-8 text-blue-600" />
            <h1 className="text-3xl font-bold text-gray-900">{title}</h1>
          </div>
          <div className="prose prose-lg max-w-none">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

// Markdown content loader component
const MarkdownContent = ({ url, title }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to load content');
        }
        const text = await response.text();
        setContent(text);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchContent();
  }, [url]);

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 p-4 rounded-md">
        <p className="text-red-700">Error loading content: {error}</p>
      </div>
    );
  }

  return (
    <LegalPage title={title} isLoading={isLoading}>
      <ReactMarkdown components={MarkdownComponents}>{content}</ReactMarkdown>
    </LegalPage>
  );
};

// Terms of Service page
export const TermsOfService = () => (
  <MarkdownContent
    title="Terms of Service"
    url="/legal/terms-of-service.md"
  />
);

// Privacy Policy page
export const PrivacyPolicy = () => (
  <MarkdownContent
    title="Privacy Policy"
    url="/legal/privacy-policy.md"
  />
);

export default { TermsOfService, PrivacyPolicy };
