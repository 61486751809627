import { create } from 'zustand';
import { Student } from '../types/student';
import { DiplomaElement, FontSettings } from '../types/diploma';
import { DiplomaTemplate, diplomaTemplates } from '../types/templates';
import { v4 as uuidv4 } from 'uuid';

interface DiplomaStore {
  backgroundImage: string | null;
  elements: DiplomaElement[];
  students: Student[];
  previewStudent: Student | null;
  selectedElement: string | null;
  selectedTemplate: DiplomaTemplate | null;
  setBackgroundImage: (image: string | null) => void;
  updateElementPosition: (id: string, position: { x: number; y: number }) => void;
  updateElementFont: (id: string, font: Partial<FontSettings>) => void;
  setStudents: (students: Student[]) => void;
  toggleElement: (id: string) => void;
  setPreviewStudent: (student: Student | null) => void;
  setSelectedElement: (id: string | null) => void;
  setTemplate: (templateId: string) => void;
  updateElementPositions: (elements: DiplomaElement[]) => void;
  initializeElements: (elements: DiplomaElement[]) => void;
  addCustomElement: (element: {
    type: string;
    text: string;
    position: { x: number; y: number };
    font: { family: string; size: number; color: string };
  }) => void;
  addImageElement: (image: {
    type: string;
    src: string;
    position: { x: number; y: number };
    width: number;
    height: number;
  }) => void;
  updateElementSize: (id: string, size: { width: number; height: number }) => void;
}

const defaultFont: FontSettings = {
  family: 'Playfair Display',
  size: 24
};

export const useDiplomaStore = create<DiplomaStore>((set) => ({
    backgroundImage: null,
    elements: [],
    students: [],
    previewStudent: null,
    selectedElement: null,
    selectedTemplate: null,
    setBackgroundImage: (image) => set({ backgroundImage: image }),
    updateElementPosition: (id, position) =>
        set((state) => ({
        elements: state.elements.map((el) =>
            el.id === id ? { ...el, position } : el
        ),
        })),
    updateElementFont: (id, font) =>
        set((state) => ({
        elements: state.elements.map((el) =>
            el.id === id ? { ...el, font: { ...el.font, ...font } } : el
        ),
        })),
    setStudents: (students) => set({ students }),
    toggleElement: (id) =>
        set((state) => ({
        elements: state.elements.map((el) =>
            el.id === id ? { ...el, enabled: !el.enabled } : el
        ),
        })),
    setPreviewStudent: (student) => set({ previewStudent: student }),
    setSelectedElement: (id) => set({ selectedElement: id }),
    setTemplate: (templateId) => {
        const template = diplomaTemplates.find(t => t.id === templateId);
        if (template) {
        set({ selectedTemplate: template });
        }
    },
    updateElementPositions: (elements) => set({ elements }),
    initializeElements: (elements) => set({ elements }),
    addCustomElement: (newElement) =>
        set((state) => ({
        elements: [...state.elements, {
            id: `custom-${Date.now()}`,
            type: 'custom',
            label: 'Custom Text',
            enabled: true,
            position: newElement.position,
            font: newElement.font,
            customText: newElement.text
        }]
        })),
    addImageElement: (image) =>
        set((state) => ({
            elements: [
                ...state.elements,
                {
                    id: uuidv4(),
                    ...image,
                    enabled: true,
                    label: 'Image Element',
                    font: defaultFont,
                },
            ],
        })),
    updateElementSize: (id, size) =>
        set((state) => ({
            elements: state.elements.map((el) =>
                el.id === id ? { ...el, ...size } : el
            ),
        })),
}));
