export interface DiplomaTemplate {
    id: string;
    name: string;
    description: string;
    backgroundUrl: string;
    elements: {
      firstName: { x: number; y: number; font: { family: string; size: number; color: string } };
      lastName: { x: number; y: number; font: { family: string; size: number; color: string } };
      course: { x: number; y: number; font: { family: string; size: number; color: string } };
      graduationDate: { x: number; y: number; font: { family: string; size: number; color: string } };
      grade: { x: number; y: number; font: { family: string; size: number; color: string } };
    };
  }

export const diplomaTemplates: DiplomaTemplate[] = [
{
    id: 'classic',
    name: 'Classic Academic',
    description: 'Traditional academic design with serif fonts and formal layout',
    backgroundUrl: '/templates/classic-diploma.svg',
    elements: {
    firstName: {
        x: 400,
        y: 280,
        font: { family: 'Old Standard TT', size: 36, color: '#1F2937' }
    },
    lastName: {
        x: 400,
        y: 330,
        font: { family: 'Old Standard TT', size: 36, color: '#1F2937' }
    },
    course: {
        x: 400,
        y: 400,
        font: { family: 'Cormorant Garamond', size: 24, color: '#4B5563' }
    },
    graduationDate: {
        x: 400,
        y: 450,
        font: { family: 'Cormorant Garamond', size: 20, color: '#6B7280' }
    },
    grade: {
        x: 400,
        y: 500,
        font: { family: 'Cormorant Garamond', size: 20, color: '#6B7280' }
    }
    }
},
{
    id: 'modern',
    name: 'Modern Professional',
    description: 'Clean and minimalist design with sans-serif typography',
    backgroundUrl: '/templates/modern-diploma.svg',
    elements: {
    firstName: {
        x: 400,
        y: 250,
        font: { family: 'Montserrat', size: 32, color: '#111827' }
    },
    lastName: {
        x: 400,
        y: 290,
        font: { family: 'Montserrat', size: 32, color: '#111827' }
    },
    course: {
        x: 400,
        y: 360,
        font: { family: 'Montserrat', size: 24, color: '#374151' }
    },
    graduationDate: {
        x: 400,
        y: 420,
        font: { family: 'Montserrat', size: 18, color: '#4B5563' }
    },
    grade: {
        x: 400,
        y: 460,
        font: { family: 'Montserrat', size: 18, color: '#4B5563' }
    }
    }
},
{
    id: 'elegant',
    name: 'Elegant Certificate',
    description: 'Sophisticated design with decorative elements and refined typography',
    backgroundUrl: '/templates/elegant-diploma.svg',
    elements: {
    firstName: {
        x: 400,
        y: 270,
        font: { family: 'Playfair Display', size: 40, color: '#1F2937' }
    },
    lastName: {
        x: 400,
        y: 320,
        font: { family: 'Playfair Display', size: 40, color: '#1F2937' }
    },
    course: {
        x: 400,
        y: 390,
        font: { family: 'Libre Baskerville', size: 24, color: '#4B5563' }
    },
    graduationDate: {
        x: 400,
        y: 440,
        font: { family: 'Libre Baskerville', size: 20, color: '#6B7280' }
    },
    grade: {
        x: 400,
        y: 480,
        font: { family: 'Libre Baskerville', size: 20, color: '#6B7280' }
    }
    }
}
];
