import { jsPDF } from 'jspdf';
import { Student } from '../types/student';
import { format } from 'date-fns';
import { useDiplomaStore } from '../store/diplomaStore';
import { setupFonts, FONT_FAMILY_MAP } from './fonts';
import { PREVIEW_DIMENSIONS } from '../config/constants';

export async function generateDiploma(student: Student, previewWidth?: number, previewHeight?: number, doc?: jsPDF) {
    // Use provided dimensions or fallback to constants
    const finalPreviewWidth = previewWidth || PREVIEW_DIMENSIONS.width;
    const finalPreviewHeight = previewHeight || PREVIEW_DIMENSIONS.height;

    console.log('Preview Sizes:', finalPreviewWidth, finalPreviewHeight);

    const { backgroundImage, elements } = useDiplomaStore.getState();

    // A4 landscape PDF dimensions in points (72 points per inch)
    const PDF_WIDTH = 842;  // 11.69 inches * 72
    const PDF_HEIGHT = 595; // 8.27 inches * 72

    const newDoc = doc || new jsPDF({
        orientation: 'landscape',
        unit: 'pt',
        format: 'a4'
    });

  await setupFonts(newDoc);

  // Add background image if available
  if (backgroundImage) {
    try {
      await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          newDoc.addImage(img, 'JPEG', 0, 0, PDF_WIDTH, PDF_HEIGHT);
          resolve(null);
        };
        img.src = backgroundImage;
      });
    } catch (error) {
      console.error('Failed to add background image:', error);
    }
  }

  // Scale factors for converting preview coordinates to PDF coordinates
  const scaleX = PDF_WIDTH / finalPreviewWidth;
  const scaleY = PDF_HEIGHT / finalPreviewHeight;

  elements.forEach((element) => {
    if (!element.enabled) return;

    if (finalPreviewWidth === 0 || finalPreviewHeight === 0) {
        console.error('Preview dimensions are zero. Cannot generate PDF.');
        return;
    }

    if (element.type === 'image' && element.src) {
      const x = element.position.x * scaleX;
      const y = element.position.y * scaleY;
      const width = (element.width || 100) * scaleX;
      const height = (element.height || 100) * scaleY;

      newDoc.addImage(element.src, 'PNG', x, y, width, height);
      return;
    }

    let text = '';
    if (element.type === 'custom') {
      text = element.customText || '';
    } else {
      text = student[element.type] || '';
      if (element.type.toLowerCase().includes('date') && text) {
        try {
          text = format(new Date(text), 'MMMM dd, yyyy');
        } catch (error) {
          console.error('Failed to format date:', error);
        }
      }
    }

    // Convert coordinates and ensure they're valid numbers
    const x = Math.max(0, Math.round(element.position.x * scaleX));
    const y = Math.max(0, Math.round(element.position.y * scaleY));
    const fontSize = Math.round(element.font.size * 1.33);
    const pdfFont = FONT_FAMILY_MAP[element.font.family] || 'helvetica';

    // Configure text properties
    newDoc.setFont(pdfFont);
    newDoc.setFontSize(fontSize);
    newDoc.setTextColor(element.font.color || '#000000');

    // Ensure text stays within PDF boundaries
    const boundedX = Math.min(Math.max(x, 0), PDF_WIDTH - 10);
    const boundedY = Math.min(Math.max(y + fontSize, fontSize), PDF_HEIGHT - 10);

    // Add text with proper alignment
    if(text.toString()){
        console.log('text:', text, 'x:', boundedX, 'y:', boundedY);

        newDoc.text(text.toString(), boundedX, boundedY, {
        align: 'center',
        });
    }
  });

  if (!doc) {
    newDoc.save(`diploma-${student.firstName}-${student.lastName}.pdf`);
  }

  return newDoc;
}

export async function generateAllDiplomas(students: Student[], previewWidth?: number, previewHeight?: number) {
    if (students.length === 0) return;

    const finalPreviewWidth = previewWidth || PREVIEW_DIMENSIONS.width;
    const finalPreviewHeight = previewHeight || PREVIEW_DIMENSIONS.height;

    const doc = await generateDiploma(students[0], finalPreviewWidth, finalPreviewHeight);

    for (let i = 1; i < students.length; i++) {
        doc.addPage();
        await generateDiploma(students[i], finalPreviewWidth, finalPreviewHeight, doc);
    }

    doc.save('All_Diplomas.pdf');
}
