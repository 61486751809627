import { useEffect } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useCreditsStore } from '../../store/creditsStore';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { checkAuth, user } = useAuthStore();
  const { fetchCredits } = useCreditsStore();

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (user) {
      fetchCredits();
    }
  }, [user]);

  return <>{children}</>;
}
