import React, { useState } from 'react';
import { Student } from '../types/student';
import { Download, FileDown, Loader2 } from 'lucide-react';
import { generateDiploma, generateAllDiplomas } from '../utils/diplomaGenerator';
import { useDiplomaStore } from '../store/diplomaStore';
import { format } from 'date-fns';
import { useCreditsStore } from '../store/creditsStore';
import { CreditsModal } from '../components/CreditsModal';
import { PREVIEW_DIMENSIONS } from '../config/constants';

interface StudentListProps {
  students: Student[];
  previewWidth: number;
  previewHeight: number;
  onGenerateDiploma: (student: Student, previewWidth: number, previewHeight: number, doc?: jsPDF) => Promise<jsPDF>;
}

export function StudentList({ students, previewWidth, previewHeight, onGenerateDiploma }: StudentListProps) {
  const { elements } = useDiplomaStore();
  const { credits, useCredits } = useCreditsStore();
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [processingStudent, setProcessingStudent] = useState<string | null>(null);
  const [processingAll, setProcessingAll] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);

  const handleGenerateAll = async () => {
    const required = students.length;

    if (credits < required) {
      setRequiredCredits(required);
      setShowCreditsModal(true);
      return;
    }

    setProcessingAll(true);
    try {
      const success = await useCredits(required);
      if (success) {
        await generateAllDiplomas(students, previewWidth, previewHeight);
      }
    } catch (error) {
      console.error('Error generating diplomas:', error);
    } finally {
      setProcessingAll(false);
    }
  };

  const handleSingleDiploma = async (student: Student) => {
    if (credits < 1) {
      setRequiredCredits(1);
      setShowCreditsModal(true);
      return;
    }

    setProcessingStudent(student.id);
    try {
      const success = await useCredits(1);
      if (success) {
        const doc = await generateDiploma(student, previewWidth, previewHeight);
        doc.save(`${student.firstName}_${student.lastName}_Diploma.pdf`);
      }
    } catch (error) {
      console.error('Error generating diploma:', error);
    } finally {
      setProcessingStudent(null);
    }
  };

  // Get column headers from elements
  const columns = elements
    .filter(el => el.type !== 'custom')
    .map(el => ({
      id: el.type,
      label: el.label
    }));

  if (students.length === 0) {
    return null;
  }

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-900">Student List</h2>
        <button
          onClick={handleGenerateAll}
          disabled={processingAll}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {processingAll ? (
            <>
              <Loader2 className="animate-spin h-5 w-5 mr-2" />
              Processing...
            </>
          ) : (
            <>
              <FileDown className="w-4 h-4 mr-2" />
              Download All Diplomas
            </>
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {columns.map(column => (
                <th
                  key={column.id}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.label}
                </th>
              ))}
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {students.map((student) => (
              <tr key={student.id} className="hover:bg-gray-50">
                {columns.map(column => (
                  <td key={column.id} className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {column.id.toLowerCase().includes('date')
                        ? format(new Date(student[column.id]), 'MMM dd, yyyy')
                        : student[column.id]}
                    </div>
                  </td>
                ))}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleSingleDiploma(student)}
                    disabled={processingStudent === student.id}
                    className="text-blue-600 hover:text-blue-900 inline-flex items-center disabled:opacity-50"
                  >
                    {processingStudent === student.id ? (
                      <>
                        <Loader2 className="animate-spin h-4 w-4 mr-1" />
                        Processing...
                      </>
                    ) : (
                      <>
                        <Download className="h-4 w-4 mr-1" />
                        Download
                      </>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CreditsModal
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
        requiredCredits={requiredCredits}
      />
    </div>
  );
}
