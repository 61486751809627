import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Check, Loader2 } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { useCreditsStore } from '../../store/creditsStore';
import { supabase } from '../../lib/supabase';
import { DbCreditPackage } from '../../types/credits';

export function PricingPage() {
    const { user } = useAuthStore();
    const { addCredits } = useCreditsStore();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<string | null>(null);
    const [packages, setPackages] = useState<DbCreditPackage[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [purchaseStatus, setPurchaseStatus] = useState<{
      success?: boolean;
      message?: string;
    }>({});

    useEffect(() => {
        async function fetchPackages() {
            try {
                const { data, error } = await supabase
                    .from('credit_packages')
                    .select('*')
                    .eq('active', true)
                    .order('price', { ascending: true });

                if (error) throw error;
                setPackages(data || []);
            } catch (err) {
                console.error('Error fetching packages:', err);
                setError('Failed to load pricing packages');
            }
        }

        fetchPackages();
    }, []);

    const handlePurchase = async (packageId: string, amount: number) => {
        if (!user) {
            navigate(`/auth/signin?redirect=/pricing&package=${packageId}`);
            return;
        }

        setIsLoading(packageId);
        try {
            await addCredits(amount, packageId);
            setPurchaseStatus({
                success: true,
                message: 'Credits added successfully!'
            });
        } catch (err) {
            setPurchaseStatus({
                success: false,
                message: 'Failed to add credits. Please try again.'
            });
        } finally {
            setIsLoading(null);
        }
    };

    if (error) {
        return (
            <div className="py-16 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto text-center">
                    <p className="text-red-600">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Simple, transparent pricing
                    </h2>
                    <p className="mt-4 text-xl text-gray-600">
                        Choose the package that fits your needs
                    </p>
                </div>

                {purchaseStatus.message && (
                    <div className={`mt-8 p-4 rounded-md ${purchaseStatus.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
                        {purchaseStatus.message}
                    </div>
                )}

                <div className="mt-16 grid gap-8 lg:grid-cols-3">
                    {packages.map((pkg) => (
                        <div key={pkg.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="px-6 py-8">
                                <h3 className="text-2xl font-semibold text-gray-900">{pkg.name}</h3>
                                <p className="mt-4 text-gray-500">{pkg.description}</p>
                                <p className="mt-8">
                                    <span className="text-4xl font-extrabold text-gray-900">
                                        ${pkg.price}
                                    </span>
                                </p>
                                <ul className="mt-8 space-y-4">
                                    <li className="flex items-center">
                                        <Check className="h-5 w-5 text-green-500" />
                                        <span className="ml-3 text-gray-500">{pkg.credits} credits</span>
                                    </li>
                                    <li className="flex items-center">
                                        <Check className="h-5 w-5 text-green-500" />
                                        <span className="ml-3 text-gray-500">No expiration</span>
                                    </li>
                                    <li className="flex items-center">
                                        <Check className="h-5 w-5 text-green-500" />
                                        <span className="ml-3 text-gray-500">Bulk generation</span>
                                    </li>
                                </ul>
                                <button
                                    onClick={() => handlePurchase(pkg.id, pkg.credits)}
                                    disabled={isLoading === pkg.id}
                                    className="mt-8 w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {isLoading === pkg.id ? (
                                        <span className="flex items-center justify-center">
                                            <Loader2 className="animate-spin h-5 w-5 mr-2" />
                                            Processing...
                                        </span>
                                    ) : user ? (
                                        'Purchase Credits'
                                    ) : (
                                        'Sign in to Purchase'
                                    )}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}


