import { GraduationCap } from 'lucide-react';
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}

export function AuthLayout({ children, title, subtitle }: AuthLayoutProps) {
  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="bg-white shadow-sm rounded-xl px-8 py-10">
          <div className="flex justify-center">
            <Link to="/" className="flex items-center gap-2">
              <GraduationCap className="h-12 w-12 text-blue-600" />
              <span className="text-2xl font-bold text-gray-900">Diplmr</span>
            </Link>
          </div>

          <div className="mt-6 text-center">
            <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
            <p className="mt-2 text-sm text-gray-600">{subtitle}</p>
          </div>

          <div className="mt-8">{children}</div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">Why choose Diplmr?</h3>
              <ul className="space-y-3">
                {[
                  'Import student data with CSV',
                  'Customize diploma layouts',
                  'Generate high-quality PDFs',
                  'GDPR compliant processing',
                  'Secure cloud storage',
                ].map((feature) => (
                  <li key={feature} className="flex items-center gap-2 text-sm text-gray-600">
                    <svg className="h-4 w-4 text-blue-500 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
