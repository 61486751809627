import React, { useMemo, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useDiplomaStore } from '../store/diplomaStore';
import { format } from 'date-fns';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

interface DraggableElementProps {
  element: {
    id: string;
    position: { x: number; y: number };
    label: string;
    font: {
      family: string;
      size: number;
      color: string;
    };
    type: string;
    customText?: string;
    src?: string;
    width?: number;
    height?: number;
  };
}

export function DraggableElement({ element }: DraggableElementProps) {
  const { previewStudent } = useDiplomaStore();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: element.id,
  });

  const [size, setSize] = useState({ width: element.width || 100, height: element.height || 100 });
  const { updateElementSize } = useDiplomaStore();

  const onResize = (event: any, { size }: { size: { width: number; height: number } }) => {
    setSize(size);
    updateElementSize(element.id, size);
  };

  const displayText = useMemo(() => {
    if (!previewStudent) {
      return element.type === 'custom' ? element.customText : `{${element.label}}`;
    }

    if (element.type === 'custom') {
      return element.customText;
    }

    if (element.type.toLowerCase().includes('date')) {
      try {
        return format(new Date(previewStudent[element.type]), 'MMMM dd, yyyy');
      } catch {
        return previewStudent[element.type] || '';
      }
    }

    return previewStudent[element.type] || '';
  }, [element, previewStudent]);

  const style = {
    position: 'absolute',
    left: `${element.position.x}px`,
    top: `${element.position.y}px`,
    fontFamily: element.font.family,
    fontSize: `${element.font.size}px`,
    color: element.font.color,
    cursor: 'move',
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    textShadow: '1px 1px 0 rgba(255,255,255,0.8), -1px -1px 0 rgba(255,255,255,0.8), 1px -1px 0 rgba(255,255,255,0.8), -1px 1px 0 rgba(255,255,255,0.8)',
    padding: '2px 4px',
    borderRadius: '2px',
    backgroundColor: 'rgba(255,255,255,0.3)',
  } as const;

  if (element.type === 'image') {
    return (
      <div ref={setNodeRef} {...attributes} style={{
        position: 'absolute',
        left: `${element.position.x}px`,
        top: `${element.position.y}px`,
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
      }}>
        <ResizableBox
          width={size.width}
          height={size.height}
          onResize={onResize}
          lockAspectRatio={true}
          minConstraints={[50, 50]}
          maxConstraints={[500, 500]}
          handle={
            <div className="resize-handle resize-handle-se" />
          }
          handleSize={[12, 12]}
        >
          <div {...listeners} className="relative group">
            <img
              src={element.src}
              alt="Diploma Element"
              className="w-full h-full object-contain select-none"
              draggable={false}
            />
            <div className="absolute inset-0 border-2 border-transparent group-hover:border-blue-500 rounded" />
          </div>
        </ResizableBox>
      </div>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {displayText}
    </div>
  );
}
