import { DndContext, DragEndEvent, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { DraggableElement } from './DraggableElement';
import { BackgroundUpload } from './BackgroundUpload';
import { ElementControls } from './ElementControls';
import { PreviewControls } from './PreviewControls';
import { FontControls } from './FontControls';
import { useDiplomaStore } from '../store/diplomaStore';
import { HelpCircle } from 'lucide-react';
import { ImportSection } from './ImportSection';
import { StudentList } from './StudentList';
import { HelpSection } from './HelpSection';
import { GetStarted } from './GetStarted';
import { generateDiploma } from '../utils/diplomaGenerator';
import { useState, useEffect } from 'react';

export function DiplomaEditor() {
  const { backgroundImage, elements, updateElementPosition, students } = useDiplomaStore();
  const sensors = useSensors(useSensor(PointerSensor));
  const [previewSize, setPreviewSize] = useState({ width: 0, height: 0 });
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, delta } = event;
    const elementId = active.id as string;
    const element = elements.find((el) => el.id === elementId);

    if (element) {
      updateElementPosition(elementId, {
        x: element.position.x + delta.x,
        y: element.position.y + delta.y,
      });
    }
  };

  useEffect(() => {
    const previewElement = document.getElementById('diploma-preview-container');
    if (previewElement) {
      setPreviewSize({
        width: previewElement.clientWidth,
        height: previewElement.clientHeight
      });
    }
  }, [backgroundImage]);

  if (!students.length) {
    return <GetStarted />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <ImportSection />

      <div className="bg-gradient-to-b from-white to-gray-50 rounded-xl shadow-lg border border-gray-200 overflow-hidden mt-8">
        <div className="border-b border-gray-200 bg-white">
          <div className="px-6 py-4">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-gray-900">
                  Design Your Diploma
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Customize the layout and appearance of your diploma template
                </p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  onClick={() => document.getElementById('diploma-editor-help')?.showModal()}
                >
                  <HelpCircle className="w-4 h-4 mr-2" />
                  Help
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 space-y-6">
              {!backgroundImage && (
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  <BackgroundUpload />
                </div>
              )}

              {backgroundImage && (
                <div className="relative bg-white rounded-lg border border-gray-200 p-4">
                  <div
                    id="diploma-preview-container"
                    className="relative w-full aspect-[1.414] rounded-lg overflow-hidden"
                  >
                    <img
                      src={backgroundImage}
                      alt="Diploma Background"
                      className="absolute inset-0 w-full h-full object-cover"
                    />

                    <DndContext
                      sensors={sensors}
                      modifiers={[restrictToParentElement]}
                      onDragEnd={handleDragEnd}
                    >
                      <div className="relative w-full h-full">
                        {elements.filter(el => el.enabled).map((element) => (
                          <DraggableElement
                            key={element.id}
                            element={element}
                          />
                        ))}
                      </div>
                    </DndContext>
                  </div>
                </div>
              )}
            </div>

            <div className="space-y-6">
              <div className="bg-white rounded-lg border border-gray-200">
                <ElementControls />
              </div>
              <div className="bg-white rounded-lg border border-gray-200">
                <FontControls />
              </div>
              <div className="bg-white rounded-lg border border-gray-200">
                <PreviewControls />
              </div>

            </div>
          </div>
        </div>

        <dialog id="diploma-editor-help" className="p-0 rounded-xl shadow-xl backdrop:bg-gray-950/50">
          <div className="w-[32rem] max-w-full">
            <div className="px-6 py-4 border-b border-gray-200">
              <h4 className="text-lg font-semibold text-gray-900">Design Your Perfect Diploma</h4>
            </div>

            <div className="px-6 py-4">
              <div className="prose prose-sm text-gray-600">
                <p>Follow these steps to create a professional diploma design:</p>
                <ol className="space-y-3">
                  <li className="flex items-start gap-3">
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 text-sm font-medium">1</span>
                    <span>Upload a background image for your diploma template</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 text-sm font-medium">2</span>
                    <span>Use the Elements panel to show/hide different fields</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 text-sm font-medium">3</span>
                    <span>Customize fonts and sizes using the Font Controls</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 text-sm font-medium">4</span>
                    <span>Drag elements directly on the preview to position them</span>
                  </li>
                </ol>

                <div className="mt-4 p-3 bg-amber-50 border border-amber-200 rounded-lg">
                  <p className="text-amber-800 flex items-center gap-2">
                    <span className="font-medium">Pro tip:</span>
                    Use the Auto-Layout feature to automatically arrange elements in a professional layout
                  </p>
                </div>
              </div>
            </div>

            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end">
              <button
                onClick={() => document.getElementById('diploma-editor-help')?.close()}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Got it
              </button>
            </div>
          </div>
        </dialog>
      </div>
      <StudentList
        students={students}
        previewWidth={previewSize.width}
        previewHeight={previewSize.height}
        onGenerateDiploma={generateDiploma}
      />
      <HelpSection />
    </div>
  );
}
