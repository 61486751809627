// src/lib/supabase.ts
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Debug logging
console.log('Environment variables status:', {
  hasUrl: !!supabaseUrl,
  hasKey: !!supabaseAnonKey,
  nodeEnv: import.meta.env.MODE,
});

if (!supabaseUrl) {
  throw new Error(
    'VITE_SUPABASE_URL is not defined. Please check your environment variables.'
  );
}

if (!supabaseAnonKey) {
  throw new Error(
    'VITE_SUPABASE_ANON_KEY is not defined. Please check your environment variables.'
  );
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    storageKey: 'diploma-gen-auth-token',
  },
});

// Test the connection
supabase.auth.getSession().catch(error => {
  console.error('Supabase connection test failed:', error);
});
