import React from 'react';
import { GraduationCap } from 'lucide-react';
import { ImportSection } from './ImportSection';

export function GetStarted() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center">
        <GraduationCap className="w-16 h-16 text-blue-600 mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Create Professional Diplomas in Minutes
        </h1>
        <p className="text-xl text-gray-600 mb-8">
          Generate beautiful diplomas for your students with our easy-to-use template system
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto mb-12">
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex items-center gap-3 mb-4">
            <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">1</span>
            <h3 className="font-semibold text-gray-900">Import Data</h3>
          </div>
          <p className="text-gray-600">Upload your student information using our CSV template</p>
        </div>

        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex items-center gap-3 mb-4">
            <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">2</span>
            <h3 className="font-semibold text-gray-900">Design Template</h3>
          </div>
          <p className="text-gray-600">Customize the layout and appearance of your diploma</p>
        </div>

        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex items-center gap-3 mb-4">
            <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">3</span>
            <h3 className="font-semibold text-gray-900">Generate PDFs</h3>
          </div>
          <p className="text-gray-600">Download professional diplomas for all your students</p>
        </div>
      </div>

      <div className="mt-12">
        <ImportSection />
      </div>
    </div>
  );
}
