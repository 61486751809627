import { createBrowserRouter } from 'react-router-dom';
import { DiplomaEditor } from '../components/DiplomaEditor';
import { SignInForm } from '../components/auth/SignInForm';
import { SignUpForm } from '../components/auth/SignUpForm';
import { PricingPage } from '../components/pages/PricingPage';
import { TermsOfService, PrivacyPolicy } from '../components/pages/LegalPages';
import { ErrorBoundary } from '../components/ErrorBoundary';
import App from '../App';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary><App /></ErrorBoundary>,
    children: [
      {
        path: '/',
        element: <DiplomaEditor />
      },
      {
        path: '/editor',
        element: <DiplomaEditor />
      },
      {
        path: '/auth/signin',
        element: <SignInForm />
      },
      {
        path: '/auth/signup',
        element: <SignUpForm />
      },
      {
        path: '/pricing',
        element: <PricingPage />
      },
      {
        path: '/terms',
        element: <TermsOfService />
      },
      {
        path: '/privacy',
        element: <PrivacyPolicy />
      }
    ]
  }
]);
