import React from 'react';
import { GripVertical, Eye, EyeOff, Type } from 'lucide-react';
import { useDiplomaStore } from '../store/diplomaStore';
import { CustomTextControl } from './CustomTextControl';
import { ImageElementControl } from './ImageElementControl';

export function ElementControls() {
  const { elements, toggleElement, selectedElement, setSelectedElement } = useDiplomaStore();

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center justify-between mb-3">
        <h3 className="font-medium text-gray-900">Diploma Elements</h3>
        <button
          type="button"
          className="text-xs text-blue-600 hover:text-blue-800"
          onClick={() => document.getElementById('element-controls-help')?.showModal()}
        >
          How to use
        </button>
      </div>

      <div className="space-y-2">
        {elements.map((element) => (
          <div
            key={element.id}
            className={`flex items-center justify-between p-3 bg-white rounded-md shadow-sm border-2 transition-colors ${
              selectedElement === element.id ? 'border-blue-500' : 'border-transparent'
            }`}
          >
            <div className="flex items-center gap-3 flex-1">
              <GripVertical className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-700">
                {element.type === 'image' ? 'Image Element' : element.label}
              </span>
            </div>
            <div className="flex items-center gap-2">
              {element.type !== 'image' && (
                <button
                  onClick={() => setSelectedElement(element.id)}
                  className={`p-1.5 rounded-md transition-colors ${
                    selectedElement === element.id
                      ? 'bg-blue-100 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
                  }`}
                  title="Edit font settings"
                >
                  <Type className="w-4 h-4" />
                </button>
              )}
              <button
                onClick={() => toggleElement(element.id)}
                className={`p-1.5 rounded-md transition-colors ${
                  element.enabled
                    ? 'text-green-600 hover:text-green-700 hover:bg-green-100'
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
                }`}
                title={element.enabled ? 'Hide element' : 'Show element'}
              >
                {element.enabled ? (
                  <Eye className="w-4 h-4" />
                ) : (
                  <EyeOff className="w-4 h-4" />
                )}
              </button>
            </div>
          </div>
        ))}
        <CustomTextControl />
        <ImageElementControl />
      </div>

      <dialog id="element-controls-help" className="p-0 rounded-lg shadow-xl backdrop:bg-gray-950/50">
        <div className="w-[32rem] max-w-full p-6">
          <h4 className="text-lg font-semibold text-gray-900 mb-4">How to Use Diploma Elements</h4>
          <div className="space-y-4 text-gray-600">
            <p>Here's how to customize your diploma elements:</p>
            <ol className="list-decimal ml-4 space-y-2">
              <li>Click the eye icon to show/hide elements on your diploma</li>
              <li>Click the text icon (Aa) to customize the font settings for that element</li>
              <li>Drag elements directly on the diploma preview to position them</li>
              <li>Use the preview section to see how your diploma looks with real data</li>
            </ol>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => document.getElementById('element-controls-help')?.close()}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Got it
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
}
