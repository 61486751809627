import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, FileDown } from 'lucide-react';
import Papa from 'papaparse';
import { Student } from '../types/student';
import { downloadSampleCsv } from '../utils/sampleData';
import { useDiplomaStore } from '../store/diplomaStore';

interface FileUploadProps {
  onStudentsImported: (students: Student[]) => void;
}

export function FileUpload({ onStudentsImported }: FileUploadProps) {
  const { initializeElements } = useDiplomaStore();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    Papa.parse(file, {
      complete: (results) => {
        // Get headers from the first row
        const headers = results.data[0] as string[];

        // Create elements based on headers
        const elements = headers.map(header => ({
          id: header.toLowerCase().replace(/\s+/g, '-'),
          type: header.toLowerCase().replace(/\s+/g, '-'),
          position: { x: 50, y: 50 },
          label: header,
          enabled: true,
          font: {
            family: 'Montserrat',
            size: 24,
            color: '#000000'
          }
        }));

        // Initialize store with dynamic elements
        initializeElements(elements);

        // Map data to students
        const students: Student[] = results.data
          .slice(1) // Skip header row
          .map((row: any, index) => {
            const student: Student = { id: `student-${index}` };
            headers.forEach((header, i) => {
              student[header.toLowerCase().replace(/\s+/g, '-')] = row[i];
            });
            return student;
          });

        onStudentsImported(students);
      },
      header: false
    });
  }, [onStudentsImported, initializeElements]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  });

  return (
    <div>
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
      >
        <input {...getInputProps()} />
        <Upload className="w-12 h-12 mx-auto text-gray-400 mb-4" />
        {isDragActive ? (
          <p className="text-lg text-blue-500">Drop the CSV file here...</p>
        ) : (
          <div>
            <p className="text-lg text-gray-600 mb-2">
              Drag & drop your students CSV file here
            </p>
            <p className="text-sm text-gray-500">
              or click to select a file
            </p>
          </div>
        )}
      </div>
      <div className="mt-4 text-center">
        <button
          onClick={downloadSampleCsv}
          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
        >
          <FileDown className="w-4 h-4 mr-1" />
          Download sample CSV template
        </button>
      </div>
    </div>
  );
}
