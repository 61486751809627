import React from 'react';
import { useDiplomaStore } from '../store/diplomaStore';

export function PreviewControls() {
  const { students, previewStudent, setPreviewStudent } = useDiplomaStore();

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <h3 className="font-medium text-gray-900 mb-3">Preview Data</h3>
      <select
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        value={previewStudent?.id || ''}
        onChange={(e) => {
          const student = students.find(s => s.id === e.target.value);
          setPreviewStudent(student || null);
        }}
      >
        <option value="">Select a student...</option>
        {students.map((student) => (
          <option key={student.id} value={student.id}>
            {student.firstName} {student.lastName}
          </option>
        ))}
      </select>
    </div>
  );
}