import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImagePlus } from 'lucide-react';
import { useDiplomaStore } from '../store/diplomaStore';
import { DIPLOMA_DIMENSIONS, IMAGE_TYPES, MAX_FILE_SIZE } from '../config/constants';

export function BackgroundUpload() {
  const setBackgroundImage = useDiplomaStore((state) => state.setBackgroundImage);
  const [showWarning, setShowWarning] = useState(false);
  const [imageData, setImageData] = useState<string | null>(null);

  const processImage = (dataUrl: string) => {
    const img = new Image();
    img.onload = () => {
      const imageRatio = img.width / img.height;
      const targetRatio = DIPLOMA_DIMENSIONS.ratio;
      const tolerance = 0.1;

      if (Math.abs(imageRatio - targetRatio) > tolerance) {
        setImageData(dataUrl);
        setShowWarning(true);
      } else {
        setBackgroundImage(dataUrl);
      }
    };
    img.src = dataUrl;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => processImage(reader.result as string);
    reader.readAsDataURL(file);
  }, []);

  const handleChooseDifferent = () => {
    setShowWarning(false);
    setImageData(null);
  };

  const handleContinueAnyway = () => {
    if (imageData) {
      setBackgroundImage(imageData);
    }
    setShowWarning(false);
    setImageData(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg']
    },
    multiple: false
  });

  // Handle click outside modal
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setShowWarning(false);
      setImageData(null);
    }
  };

  return (
    <>
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
      >
        <input {...getInputProps()} />
        <ImagePlus className="w-12 h-12 mx-auto text-gray-400 mb-4" />
        {isDragActive ? (
          <p className="text-lg text-blue-500">Drop the background image here...</p>
        ) : (
          <div>
            <p className="text-lg text-gray-600 mb-2">
              Upload your diploma background image
            </p>
            <p className="text-sm text-gray-500">
              Drag & drop an image or click to select
            </p>
          </div>
        )}
      </div>

      {showWarning && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950/50"
          onClick={handleBackdropClick}
        >
          <div
            className="w-[32rem] max-w-full bg-white rounded-xl shadow-xl"
            onClick={e => e.stopPropagation()}
          >
            <div className="px-6 py-4 border-b border-gray-200">
              <h4 className="text-lg font-semibold text-gray-900">Incorrect Image Dimensions</h4>
            </div>

            <div className="px-6 py-4">
              <div className="prose prose-sm text-gray-600">
                <p className="text-amber-600 font-medium">
                  Warning: This image may not display correctly on the final diploma.
                </p>
                <p className="mb-4">For best results:</p>
                <ul className="space-y-2">
                  <li>Use an image with 1.414:1 ratio (A4 landscape)</li>
                  <li>Recommended size: 2069×1382 pixels</li>
                  <li>Current image may be stretched or cropped</li>
                </ul>
              </div>
            </div>

            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end gap-3">
              <button
                onClick={handleChooseDifferent}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Choose Different Image
              </button>
              <button
                onClick={handleContinueAnyway}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Use Anyway
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
