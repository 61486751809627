import { jsPDF } from 'jspdf';

export const FONT_FAMILY_MAP: Record<string, string> = {
  'Playfair Display': 'times',
  'Montserrat': 'helvetica',
  'Cormorant Garamond': 'times',
  'Old Standard TT': 'times',
  'Libre Baskerville': 'times'
};

export function setupFonts(doc: jsPDF) {
  doc.setFont('helvetica');  // Default fallback font
}
