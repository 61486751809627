import { create } from 'zustand';
import { supabase } from '../lib/supabase';

interface CreditsStore {
    credits: number;
    isLoading: boolean;
    error: string | null;
    fetchCredits: () => Promise<void>;
    addCredits: (amount: number, packageId: string) => Promise<boolean>;
    useCredits: (amount: number) => Promise<boolean>;
}

export const useCreditsStore = create<CreditsStore>((set, get) => ({
    credits: 0,
    isLoading: false,
    error: null,

    fetchCredits: async () => {
      set({ isLoading: true, error: null });
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Not authenticated');

        const { data, error } = await supabase
          .from('user_credits')
          .select('credits')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        set({ credits: data?.credits || 0 });
      } catch (error) {
        set({ error: 'Failed to fetch credits' });
        console.error('Error fetching credits:', error);
      } finally {
        set({ isLoading: false });
      }
    },

    addCredits: async (amount: number, packageId: string) => {
        set({ isLoading: true, error: null });
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('Not authenticated');

          const { data: newCredits, error: creditsError } = await supabase.rpc(
            'add_credits',
            {
              p_amount: amount,
              p_user_id: user.id,
              p_package_id: packageId
            }
          );

          if (creditsError) throw creditsError;

          // Update local state
          set({ credits: newCredits });
          return true;
        } catch (error: any) {
          console.error('Error adding credits:', error);
          set({ error: error.message || 'Failed to add credits' });
          return false;
        } finally {
          set({ isLoading: false });
        }
    },

    useCredits: async (amount: number) => {
      set({ isLoading: true, error: null });
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Not authenticated');

        // Call the use_credits function
        const { data: success, error } = await supabase.rpc(
          'use_credits',
          { p_amount: amount, p_user_id: user.id }
        );

        if (error) throw error;

        // Update local state
        const currentCredits = get().credits;
        set({ credits: currentCredits - amount });
        return true;
      } catch (error) {
        set({ error: 'Failed to use credits' });
        console.error('Error using credits:', error);
        return false;
      } finally {
        set({ isLoading: false });
      }
    }

}));
