import React, { useState } from 'react';
import { FileUpload } from './FileUpload';
import { HelpCircle, CheckCircle, FileSpreadsheet, XCircle, Loader2 } from 'lucide-react';
import { useDiplomaStore } from '../store/diplomaStore';

export function ImportSection() {
  const { students, setStudents } = useDiplomaStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleStudentsImported = async (newStudents: Student[]) => {
    setIsLoading(true);
    setError(null);

    try {
      // Simulate some processing time for better UX
      await new Promise(resolve => setTimeout(resolve, 800));
      setStudents(newStudents);
    } catch (err) {
      setError('Failed to process the CSV file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
      {!students.length ? (
        // Initial upload state
        <>
          <div className="flex items-start justify-between mb-6">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                Import Students
              </h2>
              <p className="text-gray-600">
                Start by importing your student data from a CSV file
              </p>
            </div>
            <button
              className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900"
              onClick={() => document.getElementById('help-section')?.scrollIntoView({ behavior: 'smooth' })}
            >
              <HelpCircle className="w-5 h-5 mr-1" />
              Need help?
            </button>
          </div>

          {isLoading ? (
            <div className="text-center py-12">
              <Loader2 className="w-12 h-12 text-blue-600 animate-spin mx-auto mb-4" />
              <p className="text-gray-600">Processing your file...</p>
            </div>
          ) : (
            <FileUpload onStudentsImported={handleStudentsImported} />
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <XCircle className="h-5 w-5 text-red-400 mr-2" />
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}
        </>
      ) : (
        // Success state with import summary
        <div className="space-y-6">
          <div className="flex items-start gap-4">
            <div className="flex-shrink-0">
              <CheckCircle className="w-8 h-8 text-green-500" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                Students Imported Successfully
              </h2>
              <p className="text-gray-600">
                {students.length} student{students.length === 1 ? '' : 's'} ready for diploma generation
              </p>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center gap-2 mb-3">
              <FileSpreadsheet className="w-5 h-5 text-gray-400" />
              <h3 className="font-medium text-gray-900">Import Summary</h3>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <p className="text-gray-600">Courses</p>
                <p className="font-medium text-gray-900">
                  {new Set(students.map(s => s.course)).size} unique courses
                </p>
              </div>
              <div>
                <p className="text-gray-600">Graduation Dates</p>
                <p className="font-medium text-gray-900">
                  {new Set(students.map(s => s.graduationDate)).size} different dates
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between pt-4 border-t border-gray-200">
            <button
              onClick={() => setStudents([])}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Import Different File
            </button>
            <p className="text-sm text-gray-500">
              You can now customize your diploma layout below
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
